import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/Layout' 
// import Framework from './framework.js'
import HeaderBranding from '../components/HeaderBranding'
import SubHeader from '../components/SubHeader'
import ProductItem from '../components/ProductItem'
import LeftContentSlider from '../components/LeftContentSlider'
import TopContentSplit from '../components/TopContentSplit'
import FooterBranding from '../components/footer/FooterBranding'
 

const IndexPage = () => (
  <Layout>
    <HeaderBranding />
    <SubHeader title='test' />
    <ProductItem title='test' />
    <LeftContentSlider />
    <TopContentSplit />

    

    
    {/* <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p> */}
    {/* {data.content.body.map(block => Framework(block))} */}
    {/* <Link to="/page-2/">Go to page 2</Link> */}
    <FooterBranding />
  </Layout>
)

export default IndexPage
